import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-reset-common',
  templateUrl: './signup-reset-common.component.html',
  styleUrls: ['./signup-reset-common.component.scss'],
})
export class SignupResetCommonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
