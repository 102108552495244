export class Options {
  constructor(
    public id: number,
    public option_text: string,
    public score: string,
    public option_type: string,
    public order: number,
    public selected = false
  ) {}
}
