import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-browser-dialog',
  templateUrl: './change-browser-dialog.component.html',
  styleUrls: ['./change-browser-dialog.component.scss'],
})
export class ChangeBrowserDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
